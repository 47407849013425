/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 12, 2022 */

@font-face {
  font-family: "hp_simplified";
  src: url("hps-euro-w01-light-ttf-webfont.woff2") format("woff2"),
    url("hps-euro-w01-light-ttf-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "hp_simplified";
  src: url("hps-euro-w01-regular-ttf-webfont.woff2") format("woff2"),
    url("hps-euro-w01-regular-ttf-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "hp_simplified";
  src: url("hps-euro-w01-bold-ttf-webfont.woff2") format("woff2"),
    url("hps-euro-w01-bold-ttf-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@primary-color: #1ca9fa;