//@import url("https://use.typekit.net/qif4fcq.css");
@import "~antd/dist/antd.less";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./assets/font/stylesheet.css");
//@import url("https://fonts.cdnfonts.com/css/zin-sans");
//@import url("https://fonts.cdnfonts.com/css/zin-sans");
.nested2 .ant-table {
  background-color: #fff9db !important;
}
.nested2 .ant-table-cell {
  border: 1px solid #f8efc7 !important;
}
.nested2 .ant-table-thead > tr > th {
  background-color: #fff3bf !important;
  border-bottom: 1px solid #ccc;
  color: #97714c !important;
  font-weight: 500;
}
.bg404 {
  background-image: url("./assets/images/404.jpg");
}
.highlight-class {
  padding: 0;
  margin: 0;
  color: #c4640a;
  background-color: unset;
}
.anticon svg {
  display: block;
}
.main-menum.ant-menu-horizontal {
  justify-content: center !important;
  line-height: 50px !important;
  margin-left: -15px;
  margin-right: -15px;
  // background-color: rgba(0, 0, 0, 0.1) !important;
  // background-color: #854516 !important;
  //box-shadow: 0 0 5px #ddd;
  background-color: transparent;
}
.ant-card-meta-title {
  white-space: pre-line;
}
.kart .ant-card-actions {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-layout-content {
  background-color: white !important;
  min-height: calc(100vh - 188px);
}
.ant-layout-header {
  height: unset !important;
  background-color: white;
  padding: 0 15px !important;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-menu-item-selected {
  font-weight: 700;
  background-color: transparent !important;
}
.amenu {
  background-color: transparent;
  margin-top: 10px;
}
.amenu .ant-menu-item-selected {
  background-color: white !important;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.ant-drawer-body {
  padding: 0;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-layout-content {
  background-color: #eeeeee !important;
}

.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  background-color: #fafafa;
  padding: 15px 0 0 0;
}
.anticon svg {
  display: block;
}
.ant-image-preview-img {
  display: initial !important;
}
.bg404 {
  background-image: url("./assets/images/404.jpg");
}

.bg-white {
  background-color: white !important;
}

.ic {
  color: white;
  position: relative;
  display: inline-block;

  align-items: center !important;
  justify-content: center !important;
  text-align: center;
}
.layer {
  background-color: rgba(22, 22, 22, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hakkinda ul {
  list-style: unset;
  padding: 8px 30px;
}
.hakkinda ol {
  list-style-type: decimal;
  padding: 8px 30px;
}
.hakkinda ul li {
  list-style: disc;
}
.imaj {
  border: 3px solid white;
  box-shadow: 0 0 5px #ddd;
}

.ql-editor {
  min-height: 250px;
  background-color: white;
}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: @red-5;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: @red-7;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.timeline-Header.timeline-InformationCircle-widgetParent {
  border: 1px solid #eee !important;
  background-color: red !important;
}

.stats-div {
  text-align: center;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px #eee;
  padding: 20px;
  background-color: white !important;
}
.stat-card {
  border: 1px solid #ddd;
  box-shadow: 0 0 10px #eee;
  width: 100%;
}
.ant-table-thead > tr > th {
  color: @blue-6!important;
}
.Demo__some-network {
  display: inline;
  margin-right: 3px;
}
.react-share__ShareButton svg {
  border-radius: 3px !important;
}
.ql-video {
  width: 100%;
  height: 400px;
}
.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  background-color: #fafafa;
  padding: 15px 0 15px 0;
}

.ant-menu-item {
  margin-bottom: 0 !important;
}

.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.login-form {
  width: 320px;
  max-width: "95%";
}
.login-form-container {
  padding: 20px 20px 0 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fafafa;
  box-shadow: 1px 1px 10px #333;
}
.login-logo {
  width: 200px;
  margin-left: calc(100% / 2 - 100px);
}
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 18px !important;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #ddd;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input.ant-input {
  padding-top: 8px !important;
}
.ant-btn > span {
  padding-top: 1px !important;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: inherit;
}
.ant-picker {
  padding: 9px 11px 6px 11px !important;
}
.ant-select-selector {
  height: 43px !important;
  padding: 7px 11px 7px 11px !important;
}
.ant-table-cell {
  padding-top: 11px !important;
}

@primary-color: #1ca9fa;